import React from 'react';
import { Navbar, Nav, DropdownButton, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const NavBar = () => (
  <>
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">Anthony-Tien Nhat Huynh</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/about">About</Nav.Link>
        </Nav>
        <Nav>
          <DropdownButton
            menuAlign="right"
            title="Profolio"
            id="dropdown-menu-align-right"
            variant="secondary"
          >
            <Dropdown.Item eventKey="1" href="/certifications" >Certifications</Dropdown.Item>
            <Dropdown.Item eventKey="2" href="/projects">Projects</Dropdown.Item>
            <Dropdown.Item eventKey="3" href="/awards">Awards</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="4" href="/social media">Social Media</Dropdown.Item>
          </DropdownButton>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
);


export default NavBar;