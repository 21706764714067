import React from 'react';
import Typical from 'react-typical';

const HomePage = () => (
    <>
    
    <div className='App'>
        <header className='App-header'>
            <p>Hi, my name is {' '}
            <Typical 
                loop={Infinity}
                wrapper='b'
                steps={[
                    'Anthony',
                    1000,
                    'Anthony-Tien Nhat Huynh',
                    1000
                ]}
            />
            </p>
        </header>
    </div>
    </>
    
);

export default HomePage; 