import React from 'react';

const CodingTab = () => (
    <>
        <h1> Programming Languages I have studied: </h1>
        <p>Java. </p>
        <p>Python. </p>
        <p>C++. </p>
        <p>SQL. </p>
    </>
);

export default CodingTab; 