import React from 'react';
import CookingTab from './aboutTabs/CookingTab';
import CodingTab from './aboutTabs/CodingTab';

import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


export default function About() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className="center">
            <h1> About Me </h1>
            <p> Cooking and coding sums up my day. </p>
            <div id="aboutTabBar">
                <AppBar position="relative" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Cooking"  {...a11yProps(0)} />
                        <Tab label="Coding" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
            </div>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <p>
                        I have been interested in cooking since elementary school. 
                        The shows I used to watch were Iron Chef, Unwrapped, Rachel Ray, Emeril Lagasse, Paula Deen, and Giada De Laurentiis. 
                        I decided to go in the kitchen when I was in high school, working at Pei Wei.
                        Started at Appetizer station, then was promoted to Drahma, and finally Wok. 

                        During my undergraduate years, I managed to juggle school-work and cooking in the food industry. 
                        I have worked at 
                        Smoothie King (shift leader/team member), 
                        Kura Revolving Sushi (ramen, tempura, rolls, nigiri), 
                        the Cheesecake Factory (appetizer, grill, saute, designated trainer), and 
                        Whole Foods Market Bakery/Coffee Bar. 
                    </p>
                    <CookingTab/>
        </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                <p>
                        When I was in middle school, my friend introduced me to a game called ROBLOX. Everyday, my friends and I would come home from school and we would play on different places that users built.
                        One day, when my friend was unable to show up, I decided to join a place where people were scripting on an empty-green baseplate. 
                        I saw a user typed a long line of code and created tools out of thin air. 
                        My curiously asked the user on how they did it.
                        From there, the user taught me the fundamental of ROBLOX.Lua and I began to script on my own.

                        I created admin commands, invisibility detector (created a clone of the invisible character but the clone was opaque and is located in the local camera), and maps with loads of scripts.
                        When high school started, I began to retextured hats for ROBLOX. Eventually, one retextured hat was chosen and was published by ROBLOX. 
                        As my time in high school continued, playing games on ROBLOX no longer interested me so I began joining their foruming community, where I helped users fix their code or improve it. 

                        When college arrived, I majored in Computer Science as I was interested in mathematics, algorithms, and programming. 
                        At UTD, I learned multiple programming languages such as C++, Java, and Python.
                        During the last two years of my undergraduate, I took Cyber Security courses for my electives and earned an Information Assurance certification. 
                        For my senior project (internship), I was able to work with Vue.js, ElasticSearch, Kibana, and Docker. 
                        
                        After graduating, I applied to mthree in the Java Developer program, where I learned and created projects using Java, HTML, CSS, JavaScript, and React.js.
                        My peers also nominated/awarded me for the "Bright Ideas/Innovator" award at the end of my time with mthree. 

                        My goal at the moment is to get better at Front-End development, then Back-End development, and then get into Cyber Security. 
                    </p>
                    <CodingTab/>
        </TabPanel>
            </SwipeableViews>
        </div>
    );
}
