//import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import HomePage from './pages/HomePage';
import About from './pages/About';
import Certification from './pages/Certification';
import Project from './pages/Projects';
import NotFoundPage from './pages/NotFoundPage';
import NavBar from './NavBar';
import './App.css';



function App() {
  return (
    <>
      <Router>
        <div className="App">
          <h1 id="intro">_</h1>
          <div id="main">
            <NavBar />
            <div id="page-body">
              <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/about" component={About} />
                <Route path="/certifications" component={Certification} />
                <Route path="/projects" component={Project} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </>

    
  );

  
}

export default App;
